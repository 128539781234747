import SideMenuBar from "./components/SideMenuBar";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import {Menu as MENU, X} from "react-feather";
import CardLoading from "./components/CardLoading";
import API_LINK from "../components/links/Link";

export default function Profile() {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState([]);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('')
    const [phone, setPhone] = useState('');
    const [avatar, setAvatar] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        loadUserInfo();
    }, [])

    const loadUserInfo = async () => {
        try {
            setIsLoading(true)
            let formdata = new FormData();
            formdata.append("page", "UserProfile");
            formdata.append("id", localStorage.getItem("UserId"));
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            await fetch(`${API_LINK}Authentication.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        setUserInfo(result.data[0]);
                        setEmail(result.data[0].Email)
                        setFirstName(result.data[0].FirstName)
                        setLastName(result.data[0].LastName)
                        setUserName(result.data[0].UserName)
                        setPhone(result.data[0].PhoneNo)
                        setAvatar(result.data[0].Avatar.toString());
                        setIsLoading(false)
                    } else {
                        toast.error("Server Error! Please try again later.")
                        setIsLoading(false)
                    }
                }).catch(error => {
                    console.log('error', error)
                    setIsLoading(false)
                });
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }

    //
    async function submitHandle(event) {
        event.preventDefault();
        event.stopPropagation();
        var form = $('.ProfileForm');
        let formdata = new FormData(form[0]);
        let toaster;
        if (!form[0].checkValidity()) {
            toast.warn("Fill all the required(*) fields.")
        } else {
            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            try {
                formdata.append("page", "ProfileUpdate");
                formdata.append("avatar", avatar);
                formdata.append("id", localStorage.getItem("UserId"));

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${API_LINK}Authentication.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result);
                        if (result.status === 1) {
                            setTimeout(() => {
                                toast.update(toaster, {
                                    type: "success",
                                    render: "Profile Updated!",
                                    isLoading: false,
                                    autoClose: toaster,
                                    closeButton: true,
                                    pauseOnHover: false,
                                    pauseOnFocusLoss: false,
                                })
                                form.find("button").attr("disabled", false);
                                document.cookie = "UserName=" + userName;
                                document.cookie = "Avatar=" + avatar;
                                localStorage.setItem("UserName", userName);
                                localStorage.setItem("Avatar", avatar);
                                // form.trigger("reset");
                            }, 1000)
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    })
                    .catch(error => console.log('error', error));
            } catch (e) {
                console.log(e);
            }
        }
    }

    function imageChange(e) {
        let file = e.target.files[0];
        let result = e.target.result;
        const reader = new FileReader();
        reader.onload = function (e) {
            const previewImage = document.getElementById('userImage');
            previewImage.src = e.target.result;
            setAvatar(e.target.result.toString());
        }
        reader.readAsDataURL(file);
    }

    const [isMobileShow, setIsMobileShow] = useState(false);
    return (
        <>
            <div className='dashboard-container'>
                <SideMenuBar mobileShow={isMobileShow}/>
                <div className="dashboard-content" data-aos={'fade-left'}>
                    <form className='ProfileForm'
                          onSubmit={submitHandle}
                    >
                        <div className="content">

                            <button type={"button"} className='btn_dashboard_menu'
                                    onClick={() => setIsMobileShow(true)}>
                                <MENU size={16}/>
                                <span>Show Menu</span>
                            </button>
                            <button type={"button"}
                                    className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                                    onClick={() => setIsMobileShow(false)}>
                                <X size={16}/>
                            </button>
                            <h2>My Profile</h2>
                            <span>Let the world see you.</span>
                            <div className="content-card">
                                {
                                    isLoading ?
                                        <CardLoading/> : null
                                }
                                <div className="row justify-content-center">
                                    <div className="col-12 align-items-center">
                                        <input type="file" name='file' onChange={imageChange} hidden={true}/>
                                        <div className="profile-image-container">
                                            <img id='userImage'
                                                 src={avatar ? avatar : "https://static.vecteezy.com/system/resources/previews/020/911/740/non_2x/user-profile-icon-profile-avatar-user-icon-male-icon-face-icon-profile-icon-free-png.png"}
                                                 alt="User Image"/>
                                        </div>
                                        <small style={{marginTop: 7,}}>Image should be less than : 200KB</small>
                                        <button type={"button"} className='btn-pick-image' onClick={() => {
                                            $('input[name="file"]').trigger("click");
                                        }}>Pick Image
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>E-mail <code>*</code></label>
                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                                   placeholder={'Enter your e-mail'}
                                                   name={'email'} required/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>First Name <code>*</code></label>
                                            <input type="text" value={firstName}
                                                   onChange={(e) => setFirstName(e.target.value)}
                                                   placeholder={'Enter First Name'}
                                                   name={'firstname'}
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Last Name <code>*</code></label>
                                            <input type="text" value={lastName}
                                                   onChange={(e) => setLastName(e.target.value)}
                                                   placeholder={'Enter Last Name'}
                                                   name={'lastname'} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>User Name <code>*</code></label>
                                            <input type="text" value={userName}
                                                   onChange={(e) => setUserName(e.target.value)}
                                                   placeholder={'Enter User Name'}
                                                   name={'username'} required/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Phone No. <code>*</code></label>
                                            <input type="text" minLength={'11'} value={phone}
                                                   onChange={(e) => setPhone(e.target.value)}
                                                   placeholder={'Enter Phone No'}
                                                   name={'phone'} required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className='btn-submit'>Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
