import SideMenuBar from "./components/SideMenuBar";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import {Menu as MENU, X} from "react-feather";
import API_LINK from "../components/links/Link";

export default function ChangePassword() {
    const history = useHistory();
    const [old, setOld] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    async function submitHandle(event) {
        event.preventDefault();
        event.stopPropagation();
        var form = $('.ProfileForm');
        let formdata = new FormData(form[0]);
        let toaster;
        if (!form[0].checkValidity()) {
            toast.warn("Fill all the required(*) fields.")
        } else if (form.find('input[name="password"]').val().length <= 7) {
            toast.warn("Password must contains at least 8 digits");
        } else if (form.find('input[name="password"]').val() !== form.find('input[name="cpassword"]').val()) {
            toast.warn("Password & Confirm password should be same. Please recheck again.")
        } else if (form.find('input[name="old"]').val() !== localStorage.getItem("Password")) {
            toast.warn("You have entered the wrong old password. Please recheck again.")
        } else {
            if (password.toLowerCase() === confirmPassword.toLowerCase()) {
                form.find("button").attr("disabled", true);
                toaster = toast.loading("Processing...");
                try {
                    formdata.append("page", "ProfilePasswordUpdate");
                    formdata.append("id", localStorage.getItem("UserId"));

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${API_LINK}Authentication.php`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.log(result);
                            if (result.status === 1) {
                                setTimeout(() => {
                                    toast.update(toaster, {
                                        type: "success",
                                        render: "Profile password changed successfully!",
                                        isLoading: false,
                                        autoClose: toaster,
                                        closeButton : true,
                                        pauseOnHover : false,
                                        pauseOnFocusLoss : false,
                                    })
                                    setOld("");
                                    setConfirmPassword("")
                                    setPassword("")
                                    form.find("button").attr("disabled", false);
                                }, 1000)
                            } else {
                                toast.error("Server Error! Please try again later.")
                            }
                        })
                        .catch(error => console.log('error', error));
                } catch (e) {
                    console.log(e);
                }
            } else {
                toast.warn("Password & Confirm password are not same. Check again")
            }
        }
    }

    const [isMobileShow, setIsMobileShow] = useState(false);
    return (
        <>
            <div className='dashboard-container'>
                <SideMenuBar mobileShow={isMobileShow}/>
                <div className="dashboard-content" data-aos={'fade-left'}>
                    <form className='ProfileForm'
                          onSubmit={submitHandle}
                    >
                        <div className="content">
                            <button type={"button"} className='btn_dashboard_menu'
                                    onClick={() => setIsMobileShow(true)}>
                                <MENU size={16}/>
                                <span>Show Menu</span>
                            </button>
                            <button type={"button"}
                                    className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                                    onClick={() => setIsMobileShow(false)}>
                                <X size={16}/>
                            </button>
                            <h2>Change Password</h2>
                            <span>Want to secure your account?</span>
                            <div className="content-card">

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Old Password <code>*</code></label>
                                            <input type="text" value={old}
                                                   onChange={(e) => setOld(e.target.value)}
                                                   placeholder={'*******'}
                                                   name={'old'} required/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>New Password <code>*</code></label>
                                            <input type="text" value={password}
                                                   onChange={(e) => setPassword(e.target.value)}
                                                   placeholder={'*******'}
                                                   name={'password'} required/>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Confirm Password <code>*</code></label>
                                            <input type="text" value={confirmPassword}
                                                   onChange={(e) => setConfirmPassword(e.target.value)}
                                                   placeholder={'*******'}
                                                   name={'cpassword'}
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className='btn-submit'>Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
