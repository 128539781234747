import {NavLink, useHistory} from "react-router-dom";
import {Home, Lock, LogOut, Package, User, Users} from "react-feather";
import {memo, useEffect, useState} from "react";
import {VscCompassActive} from "react-icons/vsc";
import {LiaStackExchange} from "react-icons/lia";
import {MdKeyboardDoubleArrowLeft} from "react-icons/md";
import {ACCESSORIES_ICON, INVERTER_ICON, PANEL_ICON, STORAGE_ICON} from "../../assets/images/Images";

const SideMenuBar = memo(({mobileShow}) => {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState({
        Avatar: "", FirstName: "",
    });

    const loadUserInfo = async () => {
        await setUserInfo({FirstName: localStorage.getItem("UserName"), Avatar: localStorage.getItem("Avatar")})
    }
    useEffect(() => {
        if (!localStorage.getItem("UserId")) {
            history.push('/');
        } else {
            loadUserInfo();
        }
    }, []);

    return (<div className={`sidebar-menu-container ${mobileShow ? "show" : "hide"}`}>

        <div className='sidebar-menu' data-aos='fade-right'>
            <button className='btn-back-dashboard' onClick={() => {
                window.location.href = "https://dev.zeeshanhameed.net/";
            }}><MdKeyboardDoubleArrowLeft/> Back To Website
            </button>
            <div className="sidebar-userblock">
                <img
                    src={userInfo.Avatar ? userInfo.Avatar : "https://static.vecteezy.com/system/resources/previews/020/911/740/non_2x/user-profile-icon-profile-avatar-user-icon-male-icon-face-icon-profile-icon-free-png.png"}
                    alt="User Image"/>
                <h4 className="username">{userInfo.FirstName}</h4>
            </div>
            <div className="sidebar-menu-items">
                <ul>
                    <li>
                        <NavLink to={'/user/dashboard'} exact>
                            <Home/> Dashboard
                        </NavLink>
                    </li>
                    <li>

                        <NavLink to={'/user/profile'} exact>
                            <User/> My Profile</NavLink>
                    </li>
                    {
                        localStorage.getItem("Type") === 'Admin' ?
                            <>
                                <li>

                                    <NavLink to={'/user/users'} exact>
                                        <Users/> Users</NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/user/pv-modules'} exact>
                                        <img src={PANEL_ICON} alt="Panel Icon" style={{marginRight: '15px'}}/>
                                        PV Solar Modules
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/user/solar-inverter'} exact>
                                        <img src={INVERTER_ICON} alt="Panel Icon" style={{marginRight: '15px'}}/>
                                        PV Solar Inverter
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/user/energy-storage'} exact>
                                        <img src={STORAGE_ICON} alt="Panel Icon" style={{marginRight: '15px'}}/>
                                        Energy Storage
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/user/accessories'} exact>
                                        <img src={ACCESSORIES_ICON} alt="Panel Icon" style={{marginRight: '15px'}}/>
                                        Accessories
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/user/inventory'} exact>
                                        <Package/>
                                        Inventory
                                    </NavLink>
                                </li>
                            </> : <li>
                                <NavLink to={'/user/inventory'} exact>
                                    <Package/>
                                    Inventory
                                </NavLink>
                            </li>

                    }

                    <li>
                        <NavLink to={'/user/change-password'} exact>
                            <Lock/> Change Password
                        </NavLink>
                    </li>
                    <li>
                        <a href='javascript:void(0)' onClick={() => {
                            document.cookie = "UserId=" + null;
                            document.cookie = "UserName=" + null;
                            document.cookie = "Type=" + null;
                            document.cookie = "Company=" + null;
                            localStorage.removeItem("UserId");
                            localStorage.removeItem("UserName");
                            localStorage.removeItem("Type");
                            localStorage.removeItem("Company");
                            history.push("/");
                        }}>
                            <LogOut/> Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>)
})
export default SideMenuBar;
