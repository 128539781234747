import REGISTER from "../assets/images/register.png";
import Checkbox from "antd/es/checkbox/Checkbox";
import {NavLink, useHistory} from "react-router-dom";
import {useState} from "react";
import $ from 'jquery';
import {toast} from "react-toastify";
import {Eye, EyeOff} from "react-feather";
import API_LINK from "../components/links/Link";

export default function Register() {
    const [isVisible, setIsVisible] = useState(false)
    const history = useHistory();
    const onCheckboxChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    function submitHandle(event) {
        event.preventDefault();
        event.stopPropagation();
        var form = $('.RegisterForm');
        let formdata = new FormData(form[0]);
        let toaster;
        if (!form[0].checkValidity()) {
            toast.warn("Fill all the required(*) fields.")
        } else if (form.find('input[name="phone"]').val().length <= 10) {
            toast.warn("Please enter a valid phone number");
        } else if (!form.find('input[name="email"]').val().includes("@")) {
            toast.warn("Please enter a valid email address");
        } else if (form.find('input[name="password"]').val().length <= 7) {
            toast.warn("Password must contains at least 8 digits");
        } else if (form.find('input[name="password"]').val() !== form.find('input[name="cpassword"]').val()) {
            toast.warn("Password & Confirm password should be same. Please recheck again.")
        } else {
            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            try {
                formdata.append("page", "CreateUser");

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${API_LINK}Authentication.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result);
                        if (result.status === 1) {
                            setTimeout(() => {
                                toast.update(toaster, {
                                    type: "success",
                                    render: "Account have been created successfully.",
                                    isLoading: false,
                                    autoClose: toaster,
                                    closeButton: true,
                                    pauseOnHover: false,
                                    pauseOnFocusLoss: false,
                                })
                                // toast.info("We have shared a verification email.Please make sure to verify your account.");
                                form.find("button").attr("disabled", false);
                                form.trigger("reset");
                                history.push("/");
                            }, 1000)
                        } else if (result.status === 2) {
                            form.find("button").attr("disabled", false);
                            toast.update(toaster, {
                                type: "info",
                                render: "An account with this email already exist. Please login into that account or use other email address.",
                                isLoading: false,
                                autoClose: toaster,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                            })

                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    })
                    .catch(error => console.log('error', error));
            } catch (e) {
                console.log(e);
            }

        }

    }

    return (
        <>
            <div className='auth-container'>
                <div className="auth-image" data-aos='fade-right'>
                    <img src={REGISTER} loading={"lazy"} alt="Login Image"/>
                </div>
                <div className="auth-form" data-aos='fade-left'>
                    <h4 className="title">Welcome to PANEL DEALS.</h4>
                    <h4 className="title"
                        style={{fontSize: 20, fontWeight: 600, lineHeight: '0px', marginBottom: 30}}>Request An
                        Account</h4>
                    <form noValidate className='RegisterForm' onSubmit={submitHandle}>
                        <div className="register-form">
                            <div className="form-group">
                                <label>First Name <code>*</code></label>
                                <input type="text" required placeholder={'Enter First Name'}
                                       name={'firstname'}/>
                            </div>
                            <div className="form-group">
                                <label>Last Name <code>*</code></label>
                                <input type="text" required placeholder={'Enter Last Name'}
                                       name={'lastname'}/>
                            </div>
                            <div className="form-group">
                                <label>Phone Number <code>*</code></label>
                                <input type="number" required placeholder={'Enter Phone No.'}
                                       name={'phone'}/>
                            </div>
                            <div className="form-group">
                                <label>Email address <code>*</code></label>
                                <input type="text" required placeholder={'Email Address'} name={'email'}/>
                            </div>
                            <div className="form-group">
                                <label>Company Name <code>*</code></label>
                                <input type="text" required placeholder={'Company Name'} name={'company'}/>
                            </div>
                            <div className="form-group">
                                <label>Address <code>*</code></label>
                                <input type="text" required placeholder={'Address'} name={'address'}/>
                            </div>
                            <div className="form-group">
                                <label>Country <code>*</code></label>
                                <input type="text" required placeholder={'Country'} name={'country'}/>
                            </div>
                            <div className="form-group">
                                <label>City <code>*</code></label>
                                <input type="text" required placeholder={'City'} name={'city'}/>
                            </div>
                            <div className="form-group">
                                <label>Zip Code <code>*</code></label>
                                <input type="text" required placeholder={'Zip Code'} name={'zip'}/>
                            </div>
                            <div className="form-group">
                                <label>Company Website <code>*</code></label>
                                <input type="text" required placeholder={'Website'} name={'website'}/>
                            </div>
                            <div className="form-group">
                                <label>UserName <code>*</code></label>
                                <input type="text" required placeholder={'Enter UserName'}
                                       name={'username'}/>
                            </div>
                            <div className="form-group">
                                <label>Password <code>*</code></label>
                                <input type={isVisible ? "text" : "password"} required placeholder={'Password'}
                                       name={'password'}/>
                                <button type={"button"} onClick={() => setIsVisible(!isVisible)} className='btn_eye'>
                                    {
                                        isVisible ?
                                            <EyeOff/> :
                                            <Eye/>
                                    }
                                </button>
                            </div>
                            <div className="form-group">
                                <label>Confirm Password <code>*</code></label>
                                <input type={isVisible ? "text" : "password"} required placeholder={'Confirm Password'}
                                       name={'cpassword'}/>
                                <button type={"button"} onClick={() => setIsVisible(!isVisible)} className='btn_eye'>
                                    {
                                        isVisible ?
                                            <EyeOff/> :
                                            <Eye/>
                                    }
                                </button>

                            </div>
                        </div>
                        <div className="form-group space-between-content">
                            <Checkbox onChange={onCheckboxChange}>I agree to accept terms and conditions
                                and
                                Privacy
                                Policy.</Checkbox>
                        </div>
                        <div className="form-group">
                            <button type={"submit"} className='btn-login'>Register</button>
                        </div>
                        <div className="form-group">
                            <div className="row-item">
                                <span>Already have an account? </span><NavLink to={'/'}>Login</NavLink>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}
