import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navigation from "./components/navigation/Navigation";
import ScrollToTop from "./components/navigation/ScrollToTop";

AOS.init();
export default function App() {
    return (<>
        <ScrollToTop/>
        <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} newestOnTop={true} closeButton={true}
                        closeOnClick={true}/>
        <Navigation/>
    </>)
}
