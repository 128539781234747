import SideMenuBar from "./components/SideMenuBar";
import {Menu as MENU, X} from "react-feather";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import API_LINK from "../components/links/Link";
import {ACCESSORIES, INVERTER, PANEL, STORAGE} from "../assets/images/Images";
import {useHistory} from "react-router-dom";

export default function Dashboard() {
    const [activeDeliveries, setActiveDeliveries] = useState('0')
    const [pastDeliveries, setPastDeliveries] = useState('0')
    const history = useHistory();
    const [moduleData, setModuleData] = useState([]);
    const [moduleFilter, setModuleFilter] = useState([]);
    const [inverterData, setInverterData] = useState([]);
    const [inverterFilter, setInverterFilter] = useState([]);
    const [energyStorageData, setEnergyStorageData] = useState([]);
    const [energyStorageFilter, setEnergyStorageFilter] = useState([]);
    const [accessoriesData, setAccessoriesData] = useState([]);
    const [accessoriesFilter, setAccessoriesFilter] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isMobileShow, setIsMobileShow] = useState(false);
    useEffect(() => {
        LoadModules();
        LoadInverter();
        LoadEnergyStorage();
        LoadAccessories();
    }, []);

    const LoadModules = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadModules");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            setModuleData(result.data);
                            setModuleFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const LoadInverter = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadInverters");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            setInverterData(result.data);
                            setInverterFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const LoadEnergyStorage = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadEnergyStorage");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            setEnergyStorageData(result.data);
                            setEnergyStorageFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }
    const LoadAccessories = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadAccessories");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            setAccessoriesData(result.data);
                            setAccessoriesFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    return (<>
        {/*<MenuHeader page={'dashboard'}/>*/}

        <div className='dashboard-container'>
            <SideMenuBar mobileShow={isMobileShow}/>
            <div className="dashboard-content" data-aos={'fade-left'}>
                <div className="content">
                    <button className='btn_dashboard_menu' onClick={() => setIsMobileShow(true)}>
                        <MENU size={16}/>
                        <span>Show Menu</span>
                    </button>
                    <button className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                            onClick={() => setIsMobileShow(false)}>
                        <X size={16}/>
                    </button>
                    <h2>Welcome Back! {localStorage.getItem("UserName")}</h2>
                    <div className="dashboard-cards-container">
                        <div className="dashboard-card">
                            <div className="icon">
                                <img src={PANEL} alt="Solar Panel"/>
                                {/*<VscCompassActive/>*/}
                            </div>
                            <div className="detail">
                                <h2 className="heading">{moduleData.length}</h2>
                                <p>PV Solar Modules</p>
                            </div>
                        </div>
                        <div className="dashboard-card">
                            <div className="icon">
                                <img src={INVERTER} alt="Solar Inverter"/>
                            </div>
                            <div className="detail">
                                <h2 className="heading">{inverterData.length}</h2>
                                <p>PV Solar Inverters</p>
                            </div>
                        </div>
                        <div className="dashboard-card">
                            <div className="icon">
                                <img src={STORAGE} alt="Solar Storage"/>
                            </div>
                            <div className="detail">
                                <h2 className="heading">{energyStorageData.length}</h2>
                                <p>Energy Storage</p>
                            </div>
                        </div>
                        <div className="dashboard-card">
                            <div className="icon">
                                <img src={ACCESSORIES} alt="Solar Accessories"/>
                            </div>
                            <div className="detail">
                                <h2 className="heading">{accessoriesData.length}</h2>
                                <p>Accessories</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
