import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Collapse, Table} from "antd";
import {Menu as MENU, X} from "react-feather";
import SideMenuBar from "./components/SideMenuBar";
import {toast} from "react-toastify";
import API_LINK from "../components/links/Link";
import $ from 'jquery';
import {
    ACCESSORIES_ICON_LARGE,
    INVERTER_ICON_LARGE,
    PANEL_ICON_LARGE,
    STORAGE_ICON_LARGE
} from "../assets/images/Images";

export default function Inventory() {
    const history = useHistory();
    const [moduleData, setModuleData] = useState([]);
    const [moduleFilter, setModuleFilter] = useState([]);
    const [inverterData, setInverterData] = useState([]);
    const [inverterFilter, setInverterFilter] = useState([]);
    const [energyStorageData, setEnergyStorageData] = useState([]);
    const [energyStorageFilter, setEnergyStorageFilter] = useState([]);
    const [accessoriesData, setAccessoriesData] = useState([]);
    const [accessoriesFilter, setAccessoriesFilter] = useState([]);
    // Manufacturer
    const [moduleManufacturer, setModuleManufacturer] = useState([]);
    const [inverterMan, setInverterMan] = useState([]);
    const [storageMan, setStorageMan] = useState([]);
    const [accessoriesMan, setAccessoriesMan] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileShow, setIsMobileShow] = useState(false);
    useEffect(() => {
        LoadModules();
        LoadInverter();
        LoadEnergyStorage();
        LoadAccessories();
    }, []);

    const LoadModules = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadModules");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)

                    // console.log(uniqueManufacturers);
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            const uniqueManufacturers = result.data.reduce((acc, current) => {
                                // Check if the manufacturer is already in the accumulator
                                const x = acc.find(item => item.Manufacturer === current.Manufacturer);
                                // If not found, add the current item to the accumulator
                                if (!x) {
                                    acc.push(current);
                                }
                                return acc;
                            }, []);
                            setModuleManufacturer(uniqueManufacturers)
                            setModuleData(result.data);
                            setModuleFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const LoadInverter = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadInverters");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            const uniqueManufacturers = result.data.reduce((acc, current) => {
                                // Check if the manufacturer is already in the accumulator
                                const x = acc.find(item => item.Manufacturer === current.Manufacturer);
                                // If not found, add the current item to the accumulator
                                if (!x) {
                                    acc.push(current);
                                }
                                return acc;
                            }, []);
                            setInverterMan(uniqueManufacturers)
                            setInverterData(result.data);
                            setInverterFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const LoadEnergyStorage = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadEnergyStorage");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            const uniqueManufacturers = result.data.reduce((acc, current) => {
                                // Check if the manufacturer is already in the accumulator
                                const x = acc.find(item => item.Manufacturer === current.Manufacturer);
                                // If not found, add the current item to the accumulator
                                if (!x) {
                                    acc.push(current);
                                }
                                return acc;
                            }, []);
                            setStorageMan(uniqueManufacturers)
                            setEnergyStorageData(result.data);
                            setEnergyStorageFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }
    const LoadAccessories = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadAccessories");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            const uniqueManufacturers = result.data.reduce((acc, current) => {
                                // Check if the manufacturer is already in the accumulator
                                const x = acc.find(item => item.Manufacturer === current.Manufacturer);
                                // If not found, add the current item to the accumulator
                                if (!x) {
                                    acc.push(current);
                                }
                                return acc;
                            }, []);
                            setAccessoriesMan(uniqueManufacturers)
                            setAccessoriesData(result.data);
                            setAccessoriesFilter(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }
    // const handleSearch = (value) => {
    //     if (value) {
    //         const filtered = filterData.filter((item) => item.SKU.toLowerCase().includes(value.trim().toLowerCase()) || item.State.toLowerCase().includes(value.trim().toLowerCase()) || item.Manufacturer.toLowerCase().includes(value.trim().toLowerCase()));
    //         setUsersData(filtered);
    //     } else {
    //         setUsersData(filterData);
    //     }
    //
    // };

    const faqs = [
        {
            key: '1',
            label: <h4>What are my payment options?</h4>,
            children: <>
                <p>We accept credit card, direct debit, wire transfer, ACH bank-to-bank transfer, physical check, Apple
                    Pay, or Google Wallet. Credit terms are available for well-qualified customers (application
                    required). Kinect Solar uses Stripe, a leading payment platform trusted by millions of brands around
                    the world, to process payments made by credit card, ACH direct debit, Apple Pay, and Google Wallet.
                    Stripe is certified to the highest compliance standards in the industry and is incredibly simple to
                    use.</p>
                <img style={{width: '150px', display: 'block', paddingTop: '15px'}}
                     src="https://kinectsolar.com/wp-content/uploads/2023/11/PoweredByStripe-Badge-01.svg"/>
                <img style={{width: '320px', display: 'block', paddingTop: '5px'}}
                      src="https://kinectsolar.com/wp-content/uploads/2023/11/Payment-Badges-01.svg"/>
            </>,
        },
        {
            key: '2',
            label: <h4>How soon will my order ship?</h4>,
            children: <>
                <table style={{margin: '20px 0', width: 'inherit'}}>
                    <tbody>
                    <tr>
                        <th style={{backgroundColor: '#1d252c', color: '#fff'}}>Availability</th>
                        <th style={{backgroundColor: '#1d252c', color: '#fff'}}>Payment Method</th>
                        <th style={{backgroundColor: '#1d252c', color: '#fff'}}>Ships In</th>
                    </tr>
                    <tr>
                        <td rowSpan="3">Available Now</td>
                        <td>Credit card, ACH transfer, wire transfer</td>
                        <td>3-5 business days</td>
                    </tr>
                    <tr>
                        <td>ACH direct debit</td>
                        <td>6-10 business days</td>
                    </tr>
                    <tr>
                        <td>Physical check</td>
                        <td>7-14 business days</td>
                    </tr>
                    <tr>
                        <td style={{border: 0,fontSize: '9px'}} colSpan="3">There are many additional factors that impact
                            processing and shipping times. These are estimates only.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p><strong>We automatically hold your order for up to 72 hours for payment processing</strong>, unless
                    otherwise noted on your sales order. If your order is being paid on credit terms, we will require a
                    deposit. Once terms are met, your order will be shipped at the earliest available date, or whichever
                    date was coordinated with your Kinect Solar Sales Rep.</p>
                <p>Payment must be received before your order is shipped. No exceptions.</p>
            </>,
        },
        {
            key: '3',
            label: <h4>Does Kinect Solar offer storage?</h4>,
            children: <>
                <p>Yes! We offer <strong>short-term</strong> and <strong>long-term</strong> storage options at highly
                    competitive rates. Let us know when placing your order if you would like to receive a storage
                    quote.&nbsp;</p>
            </>,
        },
        {
            key: '4',
            label: <h4>Does Kinect Solar charge sales tax?</h4>,
            children: <>
                <p>Yes, Kinect Solar charges sales tax in states or jurisdictions where it has established nexus. To
                    remove sales tax, you must provide a valid and complete resale certificate for the state or
                    jurisdiction associated with the delivery address on your order. <strong>Please allow an additional
                        5-7 business days for us to process your request.</strong></p>
                <p>Download our <a
                    href="https://kinectsolar.com/wp-content/uploads/2023/11/KinectSolar_SalesTaxFAQs_Rev1.6-2023.05.05.pdf"
                    target="_blank" rel="noopener">Sales Tax FAQs</a> for additional information.</p>
            </>,
        },
        {
            key: '5',
            label: <h4>How do I place an order?</h4>,
            children: <>
                <p>You can submit an online quote by clicking on any of the SKUs above and entering the quantity you’re
                    interested in purchasing for a specific item. Don’t forget that minimum order quantities (MOQs) do
                    apply in most cases. You can also <strong><a href="mailto:sales@kinectsolar.com" target="_blank"
                                                                 rel="noopener">email us</a></strong> with the SKUs and
                    quantities you need and a member of our sales team will reach out to you promptly.</p>
            </>,
        },
        {
            key: '6',
            label: <h4>Does Kinect Solar break pallets?</h4>,
            children: <>
                <p><strong>No, we do not break pallets, not even for a fee.</strong> This allows us to streamline our
                    operations and keeps costs as low as possible for our buyers. You may, however, be able to find
                    less-than-full-pallet quantities among our clearance items.</p>
            </>,
        },
        {
            key: '7',
            label: <h4>What if I need something not on this list?</h4>,
            children: <>
                <p>Let us know! In many cases, our team can help you find what you’re looking for or recommend suitable
                    alternatives.</p>
            </>,
        },
    ];

    return (<>
        <div className='dashboard-container'>
            <SideMenuBar mobileShow={isMobileShow}/>
            <div className="dashboard-content" data-aos={'fade-left'}>
                <div className="content">
                <button type={"button"} className='btn_dashboard_menu' onClick={() => setIsMobileShow(true)}>
                        <MENU size={16}/>
                        <span>Show Menu</span>
                    </button>
                    <button type={"button"} className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                            onClick={() => setIsMobileShow(false)}>
                    <X size={16}/>
                    </button>
                    <h2>Available Inventory</h2>
                    <small>Quality equipment at amazingly low prices, every day.</small>
                    <div className="cards-tabs centered-tabs">
                        <div className="card-tab" onClick={() => {
                            const section = document.getElementById('modules');
                            if (section) {
                                section.scrollIntoView({behavior: 'smooth'});
                            }
                        }}>
                            <img src={PANEL_ICON_LARGE} alt=""/>
                            <p>Modules</p>
                        </div>
                        <div className="card-tab" onClick={() => {
                            const section = document.getElementById('inverters');
                            if (section) {
                                section.scrollIntoView({behavior: 'smooth'});
                            }
                        }}>
                            <img src={INVERTER_ICON_LARGE} alt=""/>
                            <p>Inverters</p>
                        </div>
                        <div className="card-tab" onClick={() => {
                            const section = document.getElementById('storages');
                            if (section) {
                                section.scrollIntoView({behavior: 'smooth'});
                            }
                        }}>
                            <img src={STORAGE_ICON_LARGE} alt=""/>
                            <p>Storage</p>
                        </div>
                        <div className="card-tab" onClick={() => {
                            const section = document.getElementById('accessories');
                            if (section) {
                                section.scrollIntoView({behavior: 'smooth'});
                            }
                        }}>
                            <img src={ACCESSORIES_ICON_LARGE} alt=""/>
                            <p>Accessories</p>
                        </div>
                        <div className="card-tab" onClick={() => {
                            const section = document.getElementById('faqs');
                            if (section) {
                                section.scrollIntoView({behavior: 'smooth'});
                            }
                        }}>
                            <span>FAQs</span>
                        </div>

                    </div>
                    <div className="content-card" id='modules'>
                        <div className="table-search">
                            <div className="panel-card-heading">
                                <img src={PANEL_ICON_LARGE} alt=""/>
                                <h2>PV Solar Modules</h2>
                            </div>
                        </div>
                        <div className="table-filter">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Manufacturer</label>
                                        <select name="manufacturer" id="manufacturer" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = moduleFilter.filter((item) => item.Manufacturer.toLowerCase().includes(value.trim().toLowerCase()));
                                                setModuleData(filtered);
                                            } else {
                                                setModuleData(moduleFilter)
                                            }
                                        }}>
                                            <option value="">All</option>
                                            {
                                                moduleManufacturer.map((m,i)=>{
                                                    return(
                                                        <option value={m.Manufacturer}>{m.Manufacturer}</option>
                                                    )
                                                })
                                            }
                                            {/*<option value="First Solar">First Solar</option>*/}
                                            {/*<option value="JA Solar">JA Solar</option>*/}
                                            {/*<option value="LG">LG</option>*/}
                                            {/*<option value="Mission Solar">Mission Solar</option>*/}
                                            {/*<option value="Philadelphia Solar">Philadelphia Solar</option>*/}
                                            {/*<option value="Phono Solar">Phono Solar</option>*/}
                                            {/*<option value="Qcells">Qcells</option>*/}
                                            {/*<option value="REC">REC</option>*/}
                                            {/*<option value="Trina Solar">Trina Solar</option>*/}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Price Per Watt</label>
                                        <div className="row" style={{marginTop: 0}}>
                                            <input type="text" style={{width: '48%', marginRight: '2%'}}
                                                   id={'modulePriceMin'}
                                                   placeholder={'min'} onChange={(e) => {
                                                let min = e.target.value;
                                                let max = $('#modulePriceMax').val();
                                                if (min && max) {
                                                    const filtered = moduleFilter.filter((item) => parseFloat(item.PalletPrice.replace('$', '')) >= parseFloat(min) && parseFloat(item.PalletPrice.replace('$', '')) <= parseFloat(max));
                                                    setModuleData(filtered);
                                                } else if (min) {
                                                    const filtered = moduleFilter.filter((item) => parseFloat(item.PalletPrice.replace('$', '')) >= parseFloat(min));
                                                    setModuleData(filtered);
                                                }
                                            }}/>
                                            <input type="text" style={{width: '48%'}} id={'modulePriceMax'}
                                                   placeholder={'max'} onChange={(e) => {
                                                let max = e.target.value;
                                                let min = $('#modulePriceMin').val();
                                                if (min && max) {
                                                    const filtered = moduleFilter.filter((item) => parseFloat(item.PalletPrice.replace('$', '')) >= parseFloat(min) && parseFloat(item.PalletPrice.replace('$', '')) <= parseFloat(max));
                                                    setModuleData(filtered);
                                                } else if (max) {
                                                    const filtered = moduleFilter.filter((item) => parseFloat(item.PalletPrice.replace('$', '')) >= parseFloat(max));
                                                    setModuleData(filtered);
                                                }
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Additional Filters</label>
                                        <select name="additionalFilter" id="additionalFilter" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = moduleFilter.filter((item) => item.Availability.toLowerCase().includes(value.trim().toLowerCase()));
                                                setModuleData(filtered);
                                            } else {
                                                setModuleData(moduleFilter);
                                            }
                                        }}>
                                            <option value="">All</option>
                                            <option value="Available Now">Available Now</option>
                                            <option value="Less Stock">Less Stock</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button className='btn-back-dashboard'
                                                onClick={() => {
                                                    $('#modulePriceMin').val('');
                                                    $('#modulePriceMax').val('');
                                                    $('select[name="manufacturer"]').eq(0).val("");
                                                    $('select[name="additionalFilter"]').eq(0).val("");
                                                    setModuleData(moduleFilter)
                                                }}>Clear Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table dataSource={moduleData} pagination={{pageSize: 10}}
                               loading={isLoading}
                               rowHoverable={true} style={{marginTop: 20}}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (<span>{index + 1}</span>)
                            }}/>
                            <Table.Column title="SKU" dataIndex="SKU" sorter={true} />
                            <Table.Column title="Manufacturer" dataIndex="Manufacturer" sorter={true}/>
                            <Table.Column title="Manufacturer Part Number" dataIndex="PartNo" sorter={true}/>
                            <Table.Column title="Watt" dataIndex="Watt" sorter={true}/>
                            <Table.Column title="Pallet Price" dataIndex="PalletPrice" sorter={true}/>
                            <Table.Column title="Container Price" dataIndex="ContainerPrice" sorter={true}/>
                            <Table.Column title="Module Quantity" dataIndex="ModuleQuantity" sorter={true}/>
                            <Table.Column title="Pallet Remaining" dataIndex="Remaining" sorter={true}/>
                            <Table.Column title="MOQ" dataIndex="MOQ" sorter={true}/>
                            <Table.Column title="Availability" dataIndex="Availability" sorter={true}/>
                            <Table.Column title="State" dataIndex="State" sorter={true}/>
                            <Table.Column title="Modules Per Pallet" dataIndex="ModulesPerPallet" sorter={true}/>
                            <Table.Column title="Modules Per 53' FTL" dataIndex="FTL" sorter={true}/>
                        </Table>
                        <div className="table-bottom">
                            <p>1- Pmax in watts (W) at Standard Testing Conditions (STC). Provided for reference only.
                                For detailed specifications, refer to the manufacturer datasheet.</p>
                            <p>2- Shown as $/Watt. Price reflects a 2.5% cash discount, applicable only when paying by
                                ACH transfer, wire transfer, or physical check. Not all items are available by the
                                container. Some items include special manufacturer pricing that can’t be displayed here.
                                Pricing is subject to change without notice.</p>
                            <p>3- Quantity and availability are subject to order confirmation.</p>
                            <p>4- Items marked with an asterisk (*) are shipped on oversized pallets. Some items may be
                                shipped on partial pallets when there is less than a full pallet quantity.</p>
                        </div>
                    </div>
                    <div className="content-card" id='inverters'>
                        <div className="table-search">
                            <div className="panel-card-heading">
                                <img src={INVERTER_ICON_LARGE} alt=""/>
                                <h2>PV Solar Inverter</h2>
                            </div>
                        </div>
                        <div className="table-filter">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Manufacturer</label>
                                        <select name="manufacturer" id="manufacturer" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = inverterFilter.filter((item) => item.Manufacturer.toLowerCase().includes(value.trim().toLowerCase()));
                                                setInverterData(filtered);
                                            } else {
                                                setInverterData(inverterFilter)
                                            }
                                        }}>
                                            <option value="">All</option>
                                            {
                                                inverterMan.map((m,i)=>{
                                                    return(
                                                        <option value={m.Manufacturer}>{m.Manufacturer}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/*<div className="col-3">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label>Price Per Watt</label>*/}
                                {/*        <div className="row" style={{marginTop: 0}}>*/}
                                {/*            <input type="text" style={{width: '48%', marginRight: '2%'}}*/}
                                {/*                   id={'inverterPriceMin'}*/}
                                {/*                   placeholder={'0.000'} onChange={(e) => {*/}
                                {/*                let min = e.target.value;*/}
                                {/*                let max = $('#inverterPriceMax').val();*/}
                                {/*                if (min && max) {*/}
                                {/*                    const filtered = inverterFilter.filter((item) => parseFloat(item.Price.replace('$', '')) >= parseFloat(min) && parseFloat(item.Price.replace('$', '')) <= parseFloat(max));*/}
                                {/*                    setInverterData(filtered);*/}
                                {/*                } else if (min) {*/}
                                {/*                    const filtered = inverterFilter.filter((item) => parseFloat(item.Price.replace('$', '')) >= parseFloat(min));*/}
                                {/*                    setInverterData(filtered);*/}
                                {/*                }*/}
                                {/*            }}/>*/}
                                {/*            <input type="text" style={{width: '48%'}} id={'inverterPriceMax'}*/}
                                {/*                   placeholder={''} onChange={(e) => {*/}
                                {/*                let max = e.target.value;*/}
                                {/*                let min = $('#inverterPriceMin').val();*/}
                                {/*                if (min && max) {*/}
                                {/*                    const filtered = inverterFilter.filter((item) => parseFloat(item.Price.replace('$', '')) >= parseFloat(min) && parseFloat(item.Price.replace('$', '')) <= parseFloat(max));*/}
                                {/*                    setInverterData(filtered);*/}
                                {/*                } else if (max) {*/}
                                {/*                    const filtered = inverterFilter.filter((item) => parseFloat(item.Price.replace('$', '')) >= parseFloat(max));*/}
                                {/*                    setInverterData(filtered);*/}
                                {/*                }*/}
                                {/*            }}/>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Additional Filters</label>
                                        <select name="additionalFilter" id="additionalFilter" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = inverterFilter.filter((item) => item.Availability.toLowerCase().includes(value.trim().toLowerCase()));
                                                setInverterData(filtered);
                                            } else {
                                                setInverterData(inverterFilter);
                                            }
                                        }}>
                                            <option value="">All</option>
                                            <option value="Available Now">Available Now</option>
                                            <option value="Less Stock">Less Stock</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button className='btn-back-dashboard' onClick={() => {
                                            $('#inverterPriceMin').val('');
                                            $('#inverterPriceMax').val('');
                                            $('select[name="manufacturer"]').eq(1).val("");
                                            $('select[name="additionalFilter"]').eq(1).val("");
                                            setInverterData(inverterFilter)
                                        }}>Clear Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table dataSource={inverterData} pagination={{pageSize: 10}}
                               loading={isLoading}
                               rowHoverable={true} style={{marginTop: 20}}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (<span>{index + 1}</span>)
                            }}/>
                            <Table.Column title="SKU" dataIndex="SKU" sorter={true}/>
                            <Table.Column title="Manufacturer" dataIndex="Manufacturer" sorter={true}/>
                            <Table.Column title="Manufacturer Part Number" dataIndex="PartNo" sorter={true}/>
                            <Table.Column title="Price" dataIndex="Price" sorter={true}/>
                            <Table.Column title="Quantity" dataIndex="Quantity" sorter={true}/>
                            <Table.Column title="MOQ" dataIndex="MOQ" sorter={true}/>
                            <Table.Column title="Availability" dataIndex="Availability" sorter={true}/>
                            <Table.Column title="State" dataIndex="State" sorter={true}/>
                            <Table.Column title="Max DC Input" dataIndex="DCInput" sorter={true}/>
                            <Table.Column title="Rated AC Input Power" dataIndex="OutputPower" sorter={true}/>
                            <Table.Column title="Rated AC Input Voltage" dataIndex="AcOutput" sorter={true}/>
                        </Table>
                        <div className="table-bottom">
                            <p>1- Price reflects a 2.5% cash discount, applicable only when paying by ACH transfer, wire
                                transfer, or physical check. Some items include special manufacturer pricing that can’t
                                be displayed here. Pricing is subject to change without notice.</p>
                            <p>2- Quantity and availability are subject to order confirmation.</p>
                            <p>3- Provided for reference only. For detailed specifications, refer to the manufacturer
                                datasheet.</p>
                        </div>
                    </div>
                    <div className="content-card" id='storages'>
                        <div className="table-search">
                            <div className="panel-card-heading">
                                <img src={STORAGE_ICON_LARGE} alt=""/>
                                <h2>Energy Storage</h2>
                            </div>
                        </div>
                        <div className="table-filter">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Manufacturer</label>
                                        <select name="manufacturer" id="manufacturer" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = energyStorageFilter.filter((item) => item.Manufacturer.toLowerCase().includes(value.trim().toLowerCase()));
                                                setEnergyStorageData(filtered);
                                            } else {
                                                setEnergyStorageData(energyStorageFilter)
                                            }
                                        }}>
                                            <option value="">All</option>
                                            {
                                                storageMan.map((m,i)=>{
                                                    return(
                                                        <option value={m.Manufacturer}>{m.Manufacturer}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Additional Filters</label>
                                        <select name="additionalFilter" id="additionalFilter" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = energyStorageFilter.filter((item) => item.Availability.toLowerCase().includes(value.trim().toLowerCase()));
                                                setEnergyStorageData(filtered);
                                            } else {
                                                setEnergyStorageData(energyStorageFilter);
                                            }
                                        }}>
                                            <option value="">All</option>
                                            <option value="Available Now">Available Now</option>
                                            <option value="Less Stock">Less Stock</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button className='btn-back-dashboard' onClick={() => {
                                            $('select[name="manufacturer"]').eq(2).val("");
                                            $('select[name="additionalFilter"]').eq(2).val("");
                                            setEnergyStorageData(energyStorageFilter)
                                        }}>Clear Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table dataSource={energyStorageData} pagination={{pageSize: 10}}
                               loading={isLoading}
                               rowHoverable={true} style={{marginTop: 20}}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (<span>{index + 1}</span>)
                            }}/>
                            <Table.Column title="SKU" dataIndex="SKU" sorter={true}/>
                            <Table.Column title="Manufacturer" dataIndex="Manufacturer" sorter={true}/>
                            <Table.Column title="Manufacturer Part Number" dataIndex="PartNo" sorter={true}/>
                            <Table.Column title="Price" dataIndex="Price" sorter={true}/>
                            <Table.Column title="Quantity" dataIndex="Quantity" sorter={true}/>
                            <Table.Column title="MOQ" dataIndex="MOQ" sorter={true}/>
                            <Table.Column title="Availability" dataIndex="Availability" sorter={true}/>
                            <Table.Column title="State" dataIndex="State" sorter={true}/>
                            <Table.Column title="Nominal Voltage" dataIndex="Voltage" sorter={true}/>
                            <Table.Column title="Total Energy Capacity" dataIndex="Capacity" sorter={true}/>
                        </Table>
                        <div className="table-bottom">
                            <p>1- Price reflects a 2.5% cash discount, applicable only when paying by ACH transfer, wire
                                transfer, or physical check. Some items include special manufacturer pricing that can’t
                                be displayed here. Pricing is subject to change without notice.</p>
                            <p>2- Quantity and availability are subject to order confirmation.</p>
                            <p>3- Provided for reference only. For detailed specifications, refer to the manufacturer
                                datasheet.</p>
                        </div>
                    </div>
                    <div className="content-card" id={'accessories'}>
                        <div className="table-search">
                            <div className="panel-card-heading">
                                <img src={ACCESSORIES_ICON_LARGE} alt=""/>
                                <h2>Accessories</h2>
                            </div>
                        </div>
                        <div className="table-filter">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Manufacturer</label>
                                        <select name="manufacturer" id="manufacturer" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = accessoriesFilter.filter((item) => item.Manufacturer.toLowerCase().includes(value.trim().toLowerCase()));
                                                setAccessoriesData(filtered);
                                            } else {
                                                setAccessoriesData(accessoriesFilter)
                                            }
                                        }}>
                                            <option value="">All</option>
                                            {
                                                accessoriesMan.map((m,i)=>{
                                                    return(
                                                        <option value={m.Manufacturer}>{m.Manufacturer}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Accessory Category</label>
                                        <select name="category" id="category" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = accessoriesFilter.filter((item) => item.Category.toLowerCase().includes(value.trim().toLowerCase()));
                                                setAccessoriesData(filtered);
                                            } else {
                                                setAccessoriesData(accessoriesFilter);
                                            }
                                        }}>
                                            <option value="">All</option>
                                            <option value="Inverter Accessories">Inverter Accessories</option>
                                            <option value="Storage Accessories">Storage Accessories</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>Additional Filters</label>
                                        <select name="additionalFilter" id="additionalFilter" onChange={(e) => {
                                            let value = e.target.value;
                                            if (value) {
                                                const filtered = accessoriesFilter.filter((item) => item.Availability.toLowerCase().includes(value.trim().toLowerCase()));
                                                setAccessoriesData(filtered);
                                            } else {
                                                setAccessoriesData(accessoriesFilter);
                                            }
                                        }}>
                                            <option value="">All</option>
                                            <option value="Available Now">Available Now</option>
                                            <option value="Less Stock">Less Stock</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button className='btn-back-dashboard' onClick={() => {
                                            $('select[name="category"]').eq(0).val("");
                                            $('select[name="manufacturer"]').eq(3).val("");
                                            $('select[name="additionalFilter"]').eq(3).val("");
                                            setAccessoriesData(accessoriesFilter)
                                        }}>Clear Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table dataSource={accessoriesData} pagination={{pageSize: 10}}
                               loading={isLoading}
                               rowHoverable={true} style={{marginTop: 20}}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (<span>{index + 1}</span>)
                            }}/>
                            <Table.Column title="SKU" dataIndex="SKU" sorter={true}/>
                            <Table.Column title="Manufacturer" dataIndex="Manufacturer" sorter={true}/>
                            <Table.Column title="Manufacturer Part Number" dataIndex="PartNo" sorter={true}/>
                            <Table.Column title="Price" dataIndex="Price" sorter={true}/>
                            <Table.Column title="Quantity" dataIndex="Quantity" sorter={true}/>
                            <Table.Column title="MOQ" dataIndex="MOQ" sorter={true}/>
                            <Table.Column title="Availability" dataIndex="Availability" sorter={true}/>
                            <Table.Column title="State" dataIndex="State" sorter={true}/>
                            <Table.Column title="Category" dataIndex="Category" sorter={true}/>
                        </Table>
                        <div className="table-bottom">
                            <p>1- Price reflects a 2.5% cash discount, applicable only when paying by ACH transfer, wire
                                transfer, or physical check. Some items include special manufacturer pricing that can’t
                                be displayed here. Pricing is subject to change without notice.</p>
                            <p>2- Quantity and availability are subject to order confirmation.</p>
                        </div>
                    </div>
                    <div className="content-card" id={'faqs'}>
                        <div className="table-search">
                            <div className="panel-card-heading">
                                <h2>FAQs</h2>
                            </div>
                        </div>
                        <Collapse defaultActiveKey={['1']} ghost items={faqs}/>

                    </div>
                </div>
            </div>
        </div>
    </>)
}
