import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Popconfirm, Table} from "antd";
import {Menu as MENU, X} from "react-feather";
import SideMenuBar from "./components/SideMenuBar";
import {toast} from "react-toastify";
import API_LINK from "../components/links/Link";
import {RiEjectLine} from "react-icons/ri";
import {BiCheckDouble} from "react-icons/bi";
import {MdDone} from "react-icons/md";

export default function Users() {
    const history = useHistory();
    const [usersData, setUsersData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileShow, setIsMobileShow] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        LoadUsers();
    }, []);

    const LoadUsers = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadUsers");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Authentication.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            setUsersData(result.data);
                            setFilterData(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const handleSearch = (value) => {
        if (value) {
            const filtered = filterData.filter((item) => item.FirstName.toLowerCase().includes(value.trim().toLowerCase()) || item.Email.toLowerCase().includes(value.trim().toLowerCase()) || item.PhoneNo.toLowerCase().includes(value.trim().toLowerCase()));
            setUsersData(filtered);
        } else {
            setUsersData(filterData);
        }

    };

    function markAsDone(j, status) {
        let toaster;
        toaster = toast.loading("Processing...");
        try {
            const formdata = new FormData();
            formdata.append("page", "UpdateUserStatus");
            formdata.append("id", j.id);
            formdata.append("email", j.Email);
            formdata.append("status", status);
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            fetch(`${API_LINK}Authentication.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "User Status Updated!",
                                isLoading: false,
                                autoClose: toaster,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                            })
                            LoadUsers();
                        }, 1000)
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e);
        }
    }

    return (<>
        <div className='dashboard-container'>
            <SideMenuBar mobileShow={isMobileShow}/>
            <div className="dashboard-content" data-aos={'fade-left'}>
                <div className="content">
                    <button type={"button"} className='btn_dashboard_menu' onClick={() => setIsMobileShow(true)}>
                        <MENU size={16}/>
                        <span>Show Menu</span>
                    </button>
                    <button type={"button"} className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                            onClick={() => setIsMobileShow(false)}>
                        <X size={16}/>
                    </button>
                    <h2>Users List</h2>
                    <span>want to manage the users?</span>
                    <div className="content-card">
                        <div className="table-search">
                            <input type="search" placeholder='Type to search...'
                                   onChange={(e) => handleSearch(e.target.value)}/>
                        </div>
                        <Table dataSource={usersData} pagination={{pageSize: 10}}
                               loading={isLoading}
                               rowHoverable={true}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (<span>{index + 1}</span>)
                            }}/>
                            <Table.Column title="First Name" dataIndex="FirstName"/>
                            <Table.Column title="Last Name" dataIndex="LastName"/>
                            <Table.Column title="UserName" dataIndex="UserName"/>
                            <Table.Column title="Email Address" dataIndex="Email"/>
                            <Table.Column title="Phone No." dataIndex="PhoneNo"/>
                            <Table.Column title="Date" dataIndex="Date"/>
                            <Table.Column title="Status" dataIndex="Status"/>
                            {
                                localStorage.getItem("Role") !== "User" &&
                                <>
                                    <Table.Column title="Accept" render={(j) => {
                                        return (
                                            <Popconfirm title={'Are you sure?'}
                                                        description={'You want to mark this user as active?'}
                                                        onConfirm={() => {
                                                            markAsDone(j, "Active")
                                                        }}
                                            >
                                                <Button type='primary' icon={<MdDone size={15}/>}/>
                                            </Popconfirm>
                                        )
                                    }}/>
                                    <Table.Column title="Reject" render={(j) => {
                                        return (
                                            <Popconfirm title={'Are you sure?'}
                                                        description={'You want to reject this user?'}
                                                        onConfirm={() => {
                                                            markAsDone(j, "Rejected")
                                                        }}
                                            >
                                                <Button type='primary' danger icon={<RiEjectLine size={15}/>}/>
                                            </Popconfirm>
                                        )
                                    }}/>
                                </>
                            }


                        </Table>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
