import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Button, Popconfirm, Table} from "antd";
import {Menu as MENU, Trash2, X} from "react-feather";
import SideMenuBar from "./components/SideMenuBar";
import {toast} from "react-toastify";
import API_LINK from "../components/links/Link";
import {RiEjectLine} from "react-icons/ri";
import {BiCheckDouble} from "react-icons/bi";
import {MdDone} from "react-icons/md";
import $ from "jquery";

export default function AddSolarPanels() {
    const location = useLocation();
    const history = useHistory();
    const [isMobileShow, setIsMobileShow] = useState(false);
    const [sku, setSku] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [partNo, setPartNo] = useState('');
    const [watt, setWatt] = useState('');
    const [palletPrice, setPalletPrice] = useState('');
    const [containerPrice, setContainerPrice] = useState('');
    const [moduleQuantity, setModuleQuantity] = useState('');
    const [remaining, setRemaining] = useState('');
    const [moq, setMoq] = useState('');
    const [availability, setAvailability] = useState('');
    const [state, setState] = useState('');
    const [modulePerPallet, setModulePerPallet] = useState('');
    const [ftl, setFtl] = useState('');
    const [link, setLink] = useState('');
    const [note, setNote] = useState('');
    const resetStates = () => {
        setSku('');
        setManufacturer('');
        setPartNo('');
        setWatt('');
        setPalletPrice('');
        setContainerPrice('');
        setModuleQuantity('');
        setRemaining('');
        setMoq('');
        setAvailability('');
        setState('');
        setModulePerPallet('');
        setFtl('');
        setLink('');
        setNote('')
    };

    useEffect(() => {
        const record = location.state;
        if (record) {
            setSku(record.SKU);
            setManufacturer(record.Manufacturer);
            setPartNo(record.PartNo);
            setLink(record.Link);
            setWatt(record.Watt);
            setPalletPrice(record.PalletPrice);
            setContainerPrice(record.ContainerPrice);
            setModuleQuantity(record.ModuleQuantity);
            setRemaining(record.Remaining);
            setMoq(record.MOQ);
            setAvailability(record.Availability);
            setState(record.State);
            setModulePerPallet(record.ModulesPerPallet);
            setFtl(record.FTL);
            setNote(record.Note)
        }
    }, [location]);

    async function submitHandle(event) {
        event.preventDefault();
        event.stopPropagation();
        var form = $('.SubmitForm');
        let formdata = new FormData(form[0]);
        let toaster;
        if (!form[0].checkValidity()) {
            toast.warn("Fill all the required(*) fields.")
        } else {
            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            try {
                const record = location.state;
                if (record) {
                    formdata.append("page", "UpdateSolarModules");
                    formdata.append("id", record.id);
                } else {
                    formdata.append("page", "SaveSolarModules");
                }
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                await fetch(`${API_LINK}Solar.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result);
                        if (result.status === 1) {
                            setTimeout(() => {
                                resetStates();
                                form.trigger("reset");
                                toast.update(toaster, {
                                    type: "success",
                                    render: "Record Saved!",
                                    isLoading: false,
                                    autoClose: toaster,
                                    closeButton: true,
                                    pauseOnHover: false,
                                    pauseOnFocusLoss: false,
                                })
                                if (record)
                                    history.goBack();
                            }, 1000)
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    })
                    .catch(error => console.log('error', error));
                form.find("button").attr("disabled", false);

            } catch (e) {
                console.log(e);
            }
        }
    }


    return (<>
        <div className='dashboard-container'>
            <SideMenuBar mobileShow={isMobileShow}/>
            <div className="dashboard-content" data-aos={'fade-left'}>
                <form className='SubmitForm' onSubmit={submitHandle}>
                    <div className="content">
                        <button type={"button"} className='btn_dashboard_menu' onClick={() => setIsMobileShow(true)}>
                            <MENU size={16}/>
                            <span>Show Menu</span>
                        </button>
                        <button type={"button"} className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                                onClick={() => setIsMobileShow(false)}>
                            <X size={16}/>
                        </button>
                        <h2>PV Solar Modules</h2>
                        <span>want to add more solar modules?</span>

                        <div className="content-card">
                            <div className="table-search">
                                <button type={"button"} className='btn-card-header' onClick={() => history.goBack()}>Back To Solar
                                    Modules
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>SKU <code>*</code></label>
                                        <input type="text" value={sku} onChange={(e) => setSku(e.target.value)}
                                               placeholder={'...'} autoFocus
                                               name={'sku'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Manufacturer <code>*</code></label>
                                        <input type="text" value={manufacturer}
                                               onChange={(e) => setManufacturer(e.target.value)}
                                               placeholder={'...'}
                                               name={'manufacturer'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Manufacturer Part Number <code>*</code></label>
                                        <input type="text" value={partNo} onChange={(e) => setPartNo(e.target.value)}
                                               placeholder={'...'}
                                               name={'partNo'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Document Link <code>optional</code></label>
                                        <input type="url" value={link} onChange={(e) => setLink(e.target.value)}
                                               placeholder={'...'}
                                               name={'link'}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Watt <code>*</code></label>
                                        <input type="text" value={watt} onChange={(e) => setWatt(e.target.value)}
                                               placeholder={'...'}
                                               name={'watt'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Pallet Price <code>*</code></label>
                                        <input type="text" value={palletPrice}
                                               onChange={(e) => setPalletPrice(e.target.value)}
                                               placeholder={'...'}
                                               name={'palletPrice'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Container Price <code>*</code></label>
                                        <input type="text" value={containerPrice}
                                               onChange={(e) => setContainerPrice(e.target.value)}
                                               placeholder={'...'}
                                               name={'containerPrice'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Module Quantity <code>*</code></label>
                                        <input type="text" value={moduleQuantity}
                                               onChange={(e) => setModuleQuantity(e.target.value)}
                                               placeholder={'...'}
                                               name={'qty'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Remaining <code>*</code></label>
                                        <input type="text" value={remaining}
                                               onChange={(e) => setRemaining(e.target.value)}
                                               placeholder={'...'}
                                               name={'remaining'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>MOQ <code>*</code></label>
                                        <input type="text" value={moq} onChange={(e) => setMoq(e.target.value)}
                                               placeholder={'...'}
                                               name={'moq'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Availability <code>*</code></label>
                                        <select name="availability" id="availability"
                                                onChange={(e) => setAvailability(e.target.value)} value={availability}>
                                            <option value="Available Now">Available Now</option>
                                            <option value="Less Stock">Less Stock</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>State <code>*</code></label>
                                        <input type="text" value={state} onChange={(e) => setState(e.target.value)}
                                               placeholder={'...'}
                                               name={'state'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Modules Per Pallet <code>*</code></label>
                                        <input type="text" value={modulePerPallet}
                                               onChange={(e) => setModulePerPallet(e.target.value)}
                                               placeholder={'...'}
                                               name={'modulePerPallet'} required/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Modules Per 53' FTL <code>*</code></label>
                                        <input type="text" value={ftl} onChange={(e) => setFtl(e.target.value)}
                                               placeholder={'...'}
                                               name={'ftl'} required/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Note</label>
                                        <textarea name="note" id="note" value={note}
                                                  onChange={(e) => setNote(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6" style={{marginTop: 20}}>
                                        <button type='submit' className='btn-back-dashboard'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}
