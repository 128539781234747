import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Popconfirm, Table} from "antd";
import {Edit, Edit2, Menu as MENU, Trash2, X} from "react-feather";
import SideMenuBar from "./components/SideMenuBar";
import {toast} from "react-toastify";
import API_LINK from "../components/links/Link";
import {RiEjectLine} from "react-icons/ri";
import {BiCheckDouble} from "react-icons/bi";
import {MdDone} from "react-icons/md";

export default function SolarPanels() {
    const history = useHistory();
    const [usersData, setUsersData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileShow, setIsMobileShow] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        LoadModules();
    }, []);

    const LoadModules = async () => {
        try {
            setIsLoading(true);
            let formdata = new FormData();
            formdata.append("page", "LoadModules");
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

            await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === 1) {
                        if (result.data.length > 0) {
                            setUsersData(result.data);
                            setFilterData(result.data);
                        }
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                    setIsLoading(false);
                }).catch(error => console.log('error', error));
        } catch (e) {
            console.log(e)
        }
    }

    const handleSearch = (value) => {
        if (value) {
            const filtered = filterData.filter((item) => item.SKU.toLowerCase().includes(value.trim().toLowerCase()) || item.State.toLowerCase().includes(value.trim().toLowerCase()) || item.Manufacturer.toLowerCase().includes(value.trim().toLowerCase()));
            setUsersData(filtered);
        } else {
            setUsersData(filterData);
        }

    };

    async function deleteModule(id) {
        let toaster;
        toaster = toast.loading("Processing...");
        try {
            const formdata = new FormData();
            formdata.append("page", "DeleteSolarModule");
            formdata.append("id", id);
            var requestOptions = {
                method: 'POST', body: formdata, redirect: 'follow'
            };

           await fetch(`${API_LINK}Solar.php`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    if (result.status === 1) {
                        setTimeout(() => {
                            toast.update(toaster, {
                                type: "success",
                                render: "Record Deleted!",
                                isLoading: false,
                                autoClose: toaster,
                                closeButton: true,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                            })

                        }, 1000)
                        LoadModules();
                    } else {
                        toast.error("Server Error! Please try again later.")
                    }
                })
                .catch(error => console.log('error', error));
        } catch (e) {
            console.log(e);
        }
    }

    const editRecord = async (record) => {
        try {
            history.push({pathname: '/user/add-pv-modules', state: record});
        } catch (e) {
            console.log(e)
        }
    }

    return (<>
        <div className='dashboard-container'>
            <SideMenuBar mobileShow={isMobileShow}/>
            <div className="dashboard-content" data-aos={'fade-left'}>
                <div className="content">
                    <button type={"button"} className='btn_dashboard_menu' onClick={() => setIsMobileShow(true)}>
                        <MENU size={16}/>
                        <span>Show Menu</span>
                    </button>
                    <button type={"button"} className={`btn_dashboard_close_menu ${isMobileShow ? "show" : "hide"}`}
                            onClick={() => setIsMobileShow(false)}>
                        <X size={16}/>
                    </button>
                    <h2>PV Solar Modules</h2>
                    <span>want to manage the solar modules?</span>
                    <div className="content-card">
                        <div className="table-search">
                            <input type="search" placeholder='Type to search...'
                                   onChange={(e) => handleSearch(e.target.value)}/>
                            <button className='btn-card-header' onClick={() => history.push('/user/add-pv-modules')}>Add
                                Solar Modules
                            </button>
                        </div>
                        <Table dataSource={usersData} pagination={{pageSize: 10}}
                               loading={isLoading}
                               rowHoverable={true} style={{marginTop: 40}}>
                            <Table.Column title="#" width={70} render={(j, r, index) => {
                                return (<span>{index + 1}</span>)
                            }}/>
                            <Table.Column title="SKU" dataIndex="SKU"/>
                            <Table.Column title="Manufacturer" dataIndex="Manufacturer"/>
                            <Table.Column title="Manufacturer Part Number" dataIndex="PartNo"/>
                            <Table.Column title="Watt" dataIndex="Watt"/>
                            <Table.Column title="Pallet Price" dataIndex="PalletPrice"/>
                            <Table.Column title="Container Price" dataIndex="ContainerPrice"/>
                            <Table.Column title="Module Quantity" dataIndex="ModuleQuantity"/>
                            <Table.Column title="Pallet Remaining" dataIndex="Remaining"/>
                            <Table.Column title="MOQ" dataIndex="MOQ"/>
                            <Table.Column title="Availability" dataIndex="Availability"/>
                            <Table.Column title="State" dataIndex="State"/>
                            <Table.Column title="Modules Per Pallet" dataIndex="ModulesPerPallet"/>
                            <Table.Column title="Modules Per 53' FTL" dataIndex="FTL"/>
                            <Table.Column title="Note" render={(j)=>{
                                return(
                                    <textarea style={{border : "none",outline : "none",background : "transparent",}} disabled>{j.Note}</textarea>
                                )
                            }}/>
                            {
                                localStorage.getItem("Role") !== "User" &&
                                <>
                                    <Table.Column title="Edit" render={(j) => {
                                        return (
                                            <Button type='primary' onClick={async () => {
                                                await editRecord(j)
                                            }} icon={<Edit2 size={15}/>}/>
                                        )
                                    }}/>
                                    <Table.Column title="Delete" render={(j) => {
                                        return (
                                            <Popconfirm title={'Are you sure?'}
                                                        description={'You want to remove this product?'}
                                                        onConfirm={() => {
                                                            deleteModule(j.id)
                                                        }}
                                            >
                                                <Button type='primary' danger icon={<Trash2 size={15}/>}/>
                                            </Popconfirm>
                                        )
                                    }}/>
                                </>
                            }


                        </Table>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
