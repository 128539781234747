import LOGIN from "../assets/images/login.png";
import Checkbox from "antd/es/checkbox/Checkbox";
import {NavLink, useHistory} from "react-router-dom";
import $ from "jquery";
import {toast} from "react-toastify";
import {useState} from "react";
import {Eye, EyeOff} from "react-feather";
import API_LINK from "../components/links/Link";

export default function Login() {
    const history = useHistory();
    const [isVisible, setIsVisible] = useState(false)
    const onCheckboxChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    async function submitHandle(event) {
        event.preventDefault();
        event.stopPropagation();
        var form = $('.LoginForm');
        let formdata = new FormData(form[0]);
        let toaster;
        if (!form[0].checkValidity()) {
            toast.warn("Fill all the required(*) fields.")
        } else {
            form.find("button").attr("disabled", true);
            toaster = toast.loading("Processing...");
            try {
                formdata.append("page", "UserLogin");
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${API_LINK}Authentication.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result);
                        if (result.status === 1) {
                            setTimeout(() => {
                                if (result.data.length > 0) {
                                    if (result.data[0].Status === "Pending") {
                                        toast.update(toaster, {
                                            type: "info",
                                            render: "Un-verified Account.Please make sure you have activated your account.",
                                            isLoading: false,
                                            autoClose: toaster,
                                            closeButton: true,
                                            pauseOnHover: false,
                                            pauseOnFocusLoss: false,
                                            closeOnClick: true,
                                        })
                                        form.find("button").attr("disabled", false);
                                    } else if (result.data[0].Status === "Rejected") {
                                        toast.update(toaster, {
                                            type: "warning",
                                            render: "Your account has been rejected.",
                                            isLoading: false,
                                            autoClose: toaster,
                                            closeButton: true,
                                            pauseOnHover: false,
                                            pauseOnFocusLoss: false,
                                            closeOnClick: true,
                                        })
                                        form.find("button").attr("disabled", false);
                                    } else {
                                        let user = result.data[0];
                                        localStorage.setItem("UserId", user.id)
                                        localStorage.setItem("UserName", user.UserName)
                                        localStorage.setItem("FirstName", user.FirstName)
                                        localStorage.setItem("LastName", user.LastName)
                                        localStorage.setItem("Email", user.Email)
                                        localStorage.setItem("Password", user.Password)
                                        localStorage.setItem("Date", user.Date)
                                        localStorage.setItem("Avatar", user.Avatar)
                                        localStorage.setItem("Type", user.Type)
                                        toast.update(toaster, {
                                            type: "success",
                                            render: "You have successfully logged in. Please wait! redirecting to dashboard.",
                                            isLoading: false,
                                            autoClose: toaster,
                                            closeButton: true,
                                            pauseOnHover: false,
                                            pauseOnFocusLoss: false,
                                            closeOnClick: true,
                                        })
                                        form.find("button").attr("disabled", false);
                                        form.trigger("reset");
                                        setTimeout(() => {
                                            history.push("/user/dashboard");
                                        }, 1500)
                                    }
                                } else {
                                    toast.update(toaster, {
                                        type: "warning",
                                        render: "Wrong credentials. Please make sure to enter accurate information",
                                        isLoading: false,
                                        autoClose: toaster,
                                        closeButton: true,
                                        pauseOnHover: false,
                                        pauseOnFocusLoss: false,
                                        closeOnClick: true,
                                    })
                                    form.find("button").attr("disabled", false);
                                }
                            }, 1000)
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    })
                    .catch(error => console.log('error', error));
            } catch (e) {
                console.log(e);
            }

        }
    }

    return (
        <>
            <div className='auth-container'>
                <div className="auth-image" data-aos='fade-right'>
                    <img src={LOGIN} loading={'lazy'} alt="Login Image"/>
                </div>
                <div className="auth-form" data-aos='fade-left'>
                    <form noValidate className='LoginForm' onSubmit={submitHandle}>
                        <h4 className="title">Login to PANEL DEALS.</h4>

                        <div className="form-group">
                            <label>Email address <code>*</code></label>
                            <input type="email" required placeholder={'Email Address'} name={'email'}/>
                        </div>
                        <div className="form-group">
                            <label>Password <code>*</code></label>
                            <input type={isVisible ? "text" : "password"} placeholder={'Password'} name={'password'}/>
                            <button type={"button"} onClick={() => setIsVisible(!isVisible)} className='btn_eye'>
                                {
                                    isVisible ?
                                        <EyeOff/> :
                                        <Eye/>
                                }
                            </button>
                        </div>
                        <div className="form-group space-between-content">
                            <Checkbox onChange={onCheckboxChange}>Remember me</Checkbox>
                            {/*<NavLink to={'/auth/forgot-password'}>Forgot Password</NavLink>*/}
                        </div>
                        <div className="form-group">
                            <button className='btn-login' type={"submit"}>Login</button>
                        </div>
                        <div className="form-group">
                            <div className="row-item">
                                <span>Don't have an account? </span><NavLink to={'/auth/register'}>Request an
                                account</NavLink>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}
