import {Route, Switch} from "react-router-dom";
import Login from "../../auth/Login";
import Register from "../../auth/Register";
import Dashboard from "../../dashboard/Dashboard";
import Profile from "../../dashboard/Profile";
import ChangePassword from "../../dashboard/ChangePassword";
import ActiveAppointments from "../../dashboard/ActiveAppointments";
import PastAppointments from "../../dashboard/PastAppointments";
import Users from "../../dashboard/Users";
import SolarPanels from "../../dashboard/SolarPanels";
import AddSolarPanels from "../../dashboard/AddSolarPanels";
import SolarInverter from "../../dashboard/SolarInverter";
import AddSolarInverter from "../../dashboard/AddSolarInverter";
import EnergyStorage from "../../dashboard/EnergyStorage";
import AddEnergyStorage from "../../dashboard/AddEnergyStorage";
import Accessories from "../../dashboard/Accessories";
import AddAccessories from "../../dashboard/AddAccessories";
import Inventory from "../../dashboard/Inventory";

export default function Navigation() {
    return (
        <Switch>
            <Route exact path="/" component={Login}/>
            <Route exact path="/auth/register" component={Register}/>
            <Route exact path="/user/dashboard" component={Dashboard}/>
            <Route exact path="/user/profile" component={Profile}/>
            <Route exact path="/user/users" component={Users}/>
            <Route exact path="/user/pv-modules" component={SolarPanels}/>
            <Route exact path="/user/add-pv-modules" component={AddSolarPanels}/>
            <Route exact path="/user/solar-inverter" component={SolarInverter}/>
            <Route exact path="/user/add-solar-inverter" component={AddSolarInverter}/>
            <Route exact path="/user/energy-storage" component={EnergyStorage}/>
            <Route exact path="/user/add-energy-storage" component={AddEnergyStorage}/>
            <Route exact path="/user/accessories" component={Accessories}/>
            <Route exact path="/user/inventory" component={Inventory}/>
            <Route exact path="/user/add-accessories" component={AddAccessories}/>
            <Route exact path="/user/change-password" component={ChangePassword}/>
        </Switch>
    )
}
